import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Contact() {
  return (
    <div>
      	<HelmetProvider>
          <Helmet>
        <title>Contact Us | Diosa Bali Spa | Best Spa and Massage Treatment</title>
        <meta name="title" content="Contact Us | Diosa Bali Spa | Best Spa and Massage Treatment"/>
        <link rel="canonical" href="https://diosabalispa.in/contact"/>
        <meta name="description" content="Contact us today for inquiries, appointments, and more. Our dedicated team is here to assist you in scheduling your next spa experience."/>
        <meta name="keywords" content="spa near me, near by me spa, spa, massage near me, body massage near me, spa and massage near me, foot massage, spa best near me, service massage spa near me, body massage and spa near me."/>
        <meta name="robots" content="index, follow"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title"  content="Contact Us | Diosa Bali Spa | Best Spa and Massage Treatment"/>
        <meta property="og:description" content="Contact us today for inquiries, appointments, and more. Our dedicated team is here to assist you in scheduling your next spa experience."/>
        <meta property="og:url" content="https://diosabalispa.in/contact"/>
        <meta property="og:image" content="https://diosabalispa.in/images/banner2.jpg"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="Contact Us | Diosa Bali Spa | Best Spa and Massage Treatment"/>
        <meta name="twitter:description" content="Contact us today for inquiries, appointments, and more. Our dedicated team is here to assist you in scheduling your next spa experience."/>
        <meta name="twitter:site" content="@diosabalispa"/>
        <meta name="twitter:image" content="https://diosabalispa.in/images/banner2.jpg"/>
        <meta name="twitter:creator" content="@diosabalispa"/>
        </Helmet>
			</HelmetProvider>

      <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: 'url(images/banner2.jpg)' }} data-stellar-background-ratio="0.5">
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text js-fullheight align-items-center justify-content-center">
            <div className="col-md-9 ftco-animate text-center session">
              <h1 className="mb-3 bread">Contact us</h1>
              <p className="breadcrumbs"><span className="mr-2"><a href="/">Home</a></span>  <span>/</span>  <span>Contact us</span></p>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section contact-section">
        <div className="container">
          <div className="row block-9">
          <div className="col-12 col-lg-12">
            <div className="container ftco-animate bc  md-1 mx-lg-5" style={{ width: "100%", height: "100%" }} >
            {/* <div className="col-md-12 mb-4 contactinfo">
                <h2 className="h4" style={{fontSize:"2rem"}} >Contact Information</h2>
            </div> */}

            {/* //row */}

            <div className="row mt-5">

                  <div className="col-md-3 mb-3">
                    <a href='https://maps.app.goo.gl/5nXNKy7a5sKgy7iP8' target='blank'>
                    <h3 className='head'>Madurai</h3>
                      <p>
                       Plot no - 17, K.K Nagar,<br />
                       East 1<sup>st</sup> Cross Street,<br />
                      opp. to Aishwarya silks,<br />
                      near MIOT Hospital, <br />
                      Melur Main Rd,<br />
                      Madurai - 625020.<br />
                      +91-93447 81164

                      </p>
                    </a>
                  </div>

                  <div className="col-md-3 mb-3">
                    <a href='https://maps.app.goo.gl/9iAJMhpd88t5QzHRA' target='blank'>

                      <h3 className='head'>Coimbatore</h3>
                      <p>
                      No.9, Lakshmi Urshimha Graham,<br />
                      L.N Nagar, Thulasi Garden,<br />
                      Nehru Nagar West,<br />  Kalapatti,<br />
                    
                      Coimbatore - 641048.<br />
                      +91-90250 03696
                      </p>
                    </a>
                  </div>
                  <div className="col-md-3 mb-3">
                    <a href='https://maps.app.goo.gl/mZPaNZQVVJ5VTbkh7' target='blank'>

                      <h3 className='head'>Kumbakonam</h3>
                      <p>
                      Cholaa Dynasty Hotel, <br />
                      2/9, Labour Office Street,<br />
                      Sundaraperumal Kovil, <br />
                      Thiruvalanjuli,<br /> 
                      Kumbakonam - 614208.<br />
                      +91-80565 54539

                      </p>
                    </a>
                  </div>
                  <div className="col-md-3 mb-3">
                    <a href='https://maps.app.goo.gl/vMCAGyfBE1hzaeYHA' target='blank'>

                      <h3 className='head'>Dindigul</h3>
                      <p>
                      D.no M2/3,  RM Colony,<br />
                      2nd Cross St, <br />
                      Dindigul - 624001. <br />
                      +91 99941 71561.
                      </p>
                    </a>
                  </div>

                  <div className="col-md-3 mb-3">
                    <a href='https://maps.app.goo.gl/6JA8GmcwcCunw4vS9' target='blank'>

                      <h3 className='head'>Pondicherry</h3>
                      <p>
                      Plot 59 & 60,<br />
                      12<sup>th</sup> Cross Street,<br />
                      Krishna Nagar, Lawspet <br />
                      Pondicherry - 605008. <br />
                      +91 86106 19552.
                      </p>
                    </a>
                  </div>
                </div>

 
                <div className="col-md-12 mb-3 ">
                  <p className='contactinfo'><span>Email:</span> <br />
                    <a className="an" href="mailto:diosabalispa@gmail.com">diosabalispa@gmail.com </a></p>
                </div>
                <div className="col-md-12 mb-3 ">
                  <p className='contactinfo'><span>Website:</span><br />
                    <a className="an" href="https://diosabalispa.in">www.diosabalispa.in</a></p>
                </div>

            </div>
          </div>
      
            <div className="col-12 col-lg-12 ">
              <div className="container ftco-animate bc  md-1 mx-lg-5" >
              <iframe style={{ width: "100%", height: "450px" }}  frameBorder="0" allowFullScreen src="https://www.google.com/maps/d/embed?mid=13hqzN3risvXypi7NfpnQ_ywbfTM2kKc&ehbc=2E312F" style = {{width:"100%",height:"480px"}}  
                className='py-2'
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Contact